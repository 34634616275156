.about-page {
    overflow: hidden;
    font-size: 14px;
    line-height: 28px;
    color: #666;
    img {
        max-width: 100%;
        height: auto !important;
    }
    p {
        margin-bottom: 12px;
    }
}