.investor-page-list {
    margin-bottom: 48px;
    li {
        margin-bottom: 10px;
    }
    .box {
        display: flex;
        justify-content: space-between;
        background-color: #f4f4f4;
        padding: 25px 26px 25px 20px;
        transition: .3s;
        @media screen and (max-width: 1024px) {
            padding: px2rem(40px) px2rem(20px);
        }
        &:hover {
            background-color: #405ba8;
            .box-title {
                .icon {
                    img {
                        &:nth-of-type(1) {
                            opacity: 0;
                        }
                        &:nth-of-type(2) {
                            opacity: 1;
                        }
                    }
                }
                .title {
                    color: #fff;
                }
                .date {
                    color: #fff;
                }
            }
            .down-icon {
                img {
                    &:nth-of-type(1) {
                        opacity: 0;
                    }
                    &:nth-of-type(2) {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .box-title {
        display: flex;
        align-items: center;
        width: 0;
        flex: 1;
        .icon {
            margin-right: 10px;
            position: relative;
            img {
                transition: .3s;
                &:nth-of-type(2) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                }
            }
        }
        .title {
            font-size: 16px;
            color: #333;
            font-weight: bold;
            margin-right: 15px;
            transition: .3s;
            max-width: 80%;
            @include textEllipsis;
            @media screen and (max-width: 1024px) {
                max-width: 65%;
                font-size: px2rem(30px);
            }
        }
        .date {
            font-size: 16px;
            color: #666;
            transition: .3s;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(24px);
            }
        }
    }
    .down-icon {
        position: relative;
        img {
            transition: .3s;
            &:nth-of-type(2) {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }
    }
}