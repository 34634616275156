.honor-page-list {
    padding-bottom: 35px;
    >li {
        float: left;
        width: (100%/3);
    }
    .box {
        cursor: pointer;
        display: block;
        margin: 0 14px 30px;
        background: #eee;
        &:hover {
            .box-desc {
                color: $blue;
            }
        }
    }
    .box-img {
        position: relative;
        border: 5px solid transparent;
        padding-top: (172/254)*100%;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }
    }
    .box-desc {
        padding: 0 15px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        color: #333;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    @media(max-width:750px) {
        padding-bottom: 15px;
        >li {
            width: (100%/2);
        }
        .box {
            margin: 0 6px 15px;
        }
        .box-desc {
            padding: 0 10px;
            height: 45px;
            line-height: 45px;
        }
    }
}

// 弹框
.honor-pop {
    visibility: hidden;
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    .pop-con {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 900px;
        height: 700px;
        padding-top: 50px;
    }
    .swiper-container {
        width: 80%;
    }
    .item-img {
        position: relative;
        padding-top: (580/800)*100%;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }
    }
    .item-name {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .prev {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        left: 40px;
        bottom: 30px;
        width: 24px;
        height: 24px;
        background-image: url('../images/pop-prev.jpg');
        background-repeat: no-repeat;
        background-position: center;
    }
    .next {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        left: 70px;
        bottom: 30px;
        width: 24px;
        height: 24px;
        background-image: url('../images/pop-next.jpg');
        background-repeat: no-repeat;
        background-position: center;
    }
    .close-btn {
        z-index: 10;
        cursor: pointer;
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 85px;
        height: 20px;
        background-image: url('../images/pop-close.jpg');
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media(max-width:950px) {
    .honor-pop {
        .pop-con {
            width: 100%;
            height: 100%;
            padding-top: px2rem(100px);
        }
        .swiper-container {
            width: 100%;
        }
        .item-img {
            padding-top: 100%;
        }
        .item-name {
            padding: 0 10px;
            height: auto;
            white-space: normal;
            font-size: 16px;
            line-height: 24px;
        }
        .prev {
            left: 20px;
        }
        .next {
            left: 50px;
        }
    }
}