* {
    box-sizing: border-box;
}

$blue:#405ba8;
.por {
    position: relative;
}

.wp1200 {
    max-width: 1200px;
    margin: 0 auto;
}

.site-search-box {
    position: absolute;
    top: 18px;
    right: 0;
    .search-wp {
        float: left;
        width: 266px;
        height: 28px;
        margin-right: 15px;
        overflow: hidden;
        border-radius: 3px;
        border: 1px solid #bfbfbf;
        input {
            float: left;
            width: 90%;
            height: 26px;
            padding: 0 10px;
            border: 0;
            font-size: 12px;
            color: #666;
            line-height: 1.6;
        }
        button {
            float: left;
            width: 10%;
            height: 26px;
            line-height: 26px;
            background: transparent;
            text-align: center;
            border: 0;
            font-size: 20px;
            color: #405ba8;
        }
    }
    .fask-link {
        float: left;
        .item {
            display: inline-block;
            vertical-align: top;
            width: 28px;
            height: 28px;
            background: no-repeat center;
            background-size: 100%;
            &:hover {
                opacity: .8;
            }
        }
    }
}

.site-header {
    background: #fff;
    border-bottom: 1px solid #405ba8;
    .wp1200 {
        position: relative;
        z-index: 5;
        height: 120px;
    }
    .logo {
        float: left;
        padding-top: 28px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .navber-list {
        float: right;
        padding: 66px 0 0;
        .nav-item {
            position: relative;
            float: left;
            &:last-child {
                .englist {
                    &::after {
                        content: '';
                        width: 1px;
                        height: 15px;
                        background-color: #ccc;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            &:hover {
                .link {
                    color: #fff;
                    background: $blue;
                }
                .sub-nav {
                    display: block;
                }
            }
            &.active {
                .link {
                    color: #fff;
                    background: $blue;
                }
            }
            .link {
                display: block;
                height: 36px;
                line-height: 36px;
                padding: 0 20px;
                font-size: 15px;
                color: #333;
            }
            .englist {
                display: block;
                height: 36px;
                line-height: 36px;
                padding: 0 13px;
                font-size: 14px;
                color: #405ba8;
            }
        }
    }
    .sub-nav {
        display: none;
        position: absolute;
        width: 226px;
        background: #fff;
        border-top: 15px solid transparent;
        padding: 15px;
        li {
            position: relative;
            padding-left: 15px;
            margin-bottom: 14px;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 5px;
                border-left: 4px solid #405ba8;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
            }
        }
        a {
            display: block;
            font-size: 14px;
            color: #333;
            &:hover {
                color: $blue;
            }
        }
    }
}

.mo-site-header {
    display: none;
    overflow: hidden;
    border-bottom: 1px solid #405ba8;
    background: #fff;
    .logo {
        float: left;
        padding: px2rem(15px) 0 px2rem(15px) px2rem(15px);
        height: px2rem(120px);
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .meun-btn {
        float: right;
        width: px2rem(100px);
        height: px2rem(120px);
        background: url('../images/icon10.png') no-repeat center;
        background-size: px2rem(56px);
        &.active {
            background-image: url('../images/icon10-on.png');
        }
    }
}

.mo-navbar {
    display: none;
    position: fixed;
    top: px2rem(120px);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    background: #f8f8f8;
    overflow: hidden;
    .nav-search-con {
        padding: 25px 15px;
        .box {
            display: flex;
            height: 42px;
            border: 1px solid #d2d2d2;
            border-radius: 6px;
            background: #fff;
        }
        input {
            flex: 1;
            padding: 0 10px;
            font-size: 14px;
            line-height: 1.6;
            border: 0;
            outline: 0;
            background: transparent;
        }
        .btn {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            background: transparent;
            border: 0;
            outline: 0;
            font-size: 24px;
            color: #1275a0;
            tab-size: center;
        }
    }
}

.nav-phone-ul {
    border-top: 1px solid #ddd;
    .li {
        .hd {
            .one-link {
                width: auto;
            }
        }
    }
    .hd {
        display: flex;
        padding: 0 px2rem(25px);
        border-bottom: 1px solid #ddd;
        .one-link {
            display: block;
            width: 100%;
            height: px2rem(100px);
            line-height: px2rem(100px);
            color: #333;
            font-size: px2rem(32px);
        }
        .icon-btn {
            flex: 1;
            height: px2rem(100px);
            background: url('../images/icon11.png') no-repeat right center;
            background-size: px2rem(40px);
            color: #fff;
        }
    }
    .two {
        display: none;
        background: #f3fafc;
        >li {
            position: relative;
            border-bottom: 1px solid #dadee0;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: px2rem(20px);
                width: 2px;
                height: 14px;
                transform: translate(0, -50%);
                background: #1275a0;
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: px2rem(20px);
                width: px2rem(40px);
                height: px2rem(40px);
                transform: translate(0, -50%) rotate(-90deg);
                background: url('../images/icon11.png') no-repeat center;
                background-size: px2rem(40px);
            }
        }
        .two-link {
            display: block;
            padding: 0 px2rem(60px) 0 px2rem(40px);
            height: px2rem(100px);
            line-height: px2rem(100px);
            color: #666;
            font-size: px2rem(32px);
        }
    }
}

.site-footer {
    display: block;
    padding: 45px 0 75px;
    background: #405ba8;
}

.site-fasklink-con {
    float: left;
    dl {
        float: left;
        padding-right: 35px;
    }
    dt {
        margin-bottom: 8px;
        a {
            font-size: 16px;
            color: #fff;
        }
    }
    .line {
        width: 90px;
        height: 2px;
        border-top: 1px solid #025181;
        border-bottom: 1px solid #536cb1;
        margin-bottom: 10px;
    }
    dd {
        margin-bottom: 12px;
        a {
            font-size: 12px;
            color: #b4bcd8;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.about-us-con {
    float: right;
    width: 330px;
    .two-code {
        float: left;
        width: 104px;
        margin-right: 12px;
        text-align: center;
        img {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 5px;
        }
        p {
            font-size: 14px;
            line-height: 22px;
            color: #fff;
        }
    }
    .info {
        overflow: hidden;
        color: #fff;
        h4 {
            font-size: 16px;
        }
        h5 {
            font-family: 'conv';
            font-size: 28px;
            margin-bottom: 18px;
        }
        p {
            font-size: 13px;
            margin-bottom: 5px;
        }
    }
}

.site-footer-info {
    padding: 25px 0;
    background: #334a8c;
    color: #fff;
    font-size: 14px;
    a {
        color: #fff;
        font-size: 14px;
        &:hover {
            text-decoration: underline;
        }
    }
}

@media(max-width:1150px) {
    .site-header {
        display: none;
    }
    .mo-site-header {
        display: block;
    }
    .site-footer {
        padding: 25px 10px;
    }
    .site-fasklink-con {
        display: none;
    }
    .about-us-con {
        width: 100%;
        .two-code {
            width: 100px;
        }
        .info {
            h5 {
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
    }
    .site-footer-info {
        padding: 20px 0;
        text-align: center;
        font-size: 13px;
        a {
            font-size: 13px;
        }
    }
}

// 栏目页
.common-banner {
    display: block;
    height: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.mo-common-banner {
    display: none;
    padding-top: (350/640)*100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media(max-width:1150px) {
    .common-banner {
        display: none;
    }
    .mo-common-banner {
        display: block;
    }
}

.inside-page {
    max-width: 1200px;
    margin: 0 auto;
}

.common-left {
    position: relative;
    z-index: 1;
    top: -60px;
    float: left;
    width: 272px;
    min-height: 550px;
    background: url('../images/bg01.png') no-repeat center;
}

.common-bg01 {
    position: absolute;
    width: 324px;
    height: 170px;
    top: 0;
    left: -50px;
    background: url('../images/bg02.png') no-repeat;
}

.common-title {
    position: relative;
    z-index: 1;
    padding: 25px 0 0 25px;
    height: 100px;
    color: #fff;
    .cn {
        margin-bottom: 2px;
        font-size: 20px;
        font-weight: bold;
    }
    .en {
        font-family: 'Arial';
        font-size: 15px;
        text-transform: uppercase;
    }
}

.common-nav-two {
    padding: 10px 24px;
    >li {
        line-height: 52px;
        border-bottom: 1px dashed #e0e0e0;
        &:hover {
            a {
                color: $blue;
            }
        }
        &.active {
            border-bottom: 0;
            a {
                background: #405ba8;
                color: #fff;
            }
            a:before {
                background: #fff;
            }
        }
    }
    a {
        position: relative;
        display: block;
        padding-left: 16px;
        font-size: 16px;
        color: #333;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 6px;
            width: 2px;
            height: 12px;
            transform: translate(0, -50%);
            background: #2f4688;
        }
    }
}

.common-right {
    overflow: hidden;
    padding: 20px 0 0 46px;
}

.common-right-title {
    overflow: hidden;
    padding-bottom: 8px;
    border-bottom: 1px solid #ebebeb;
    .h4 {
        float: left;
        font-size: 18px;
        font-weight: bold;
        color: #405ba8;
    }
    .bread-con {
        float: right;
        margin-top: 5px;
        .home {
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: -4px;
            background: url('../images/icon04.png') no-repeat center;
            background-size: 100%;
        }
        a {
            font-size: 14px;
            color: #666;
            &:hover {
                text-decoration: underline;
                color: $blue;
            }
        }
    }
}

.inside-main-con {
    padding: 40px 0 90px;
}

@media(max-width:1150px) {
    .inside-page {
        max-width: 1200px;
        margin: 0 auto;
    }
    .common-left {
        display: none;
    }
    .common-right {
        padding: 15px 8px 30px;
    }
    .common-right-title {
        .h4 {
            float: none;
            width: 100%;
            text-align: center;
        }
        .bread-con {
            display: none;
        }
    }
    .inside-main-con {
        padding: 15px 0 0;
    }
}

.paged {
    text-align: center;
    a {
        display: inline-block;
        vertical-align: top;
        margin: 0 2px 5px;
        padding: 7px 10px;
        border: 1px solid #d4d4d4;
        font-size: 13px;
        color: #333;
        &:hover {
            border-color: $blue;
            color: #fff;
            background: $blue;
        }
        &.current {
            border-color: $blue;
            color: #fff;
            background: $blue;
        }
    }
}

.site-fixed-con {
    position: fixed;
    z-index: 100;
    right: 50px;
    top: 40%;
    .box {
        cursor: pointer;
        display: block;
        width: 48px;
        height: 48px;
        margin-bottom: 2px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #405ba8;
        .link {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .two-code-box {
        position: relative;
        &:hover {
            .img {
                display: block;
            }
        }
        .img {
            display: none;
            position: absolute;
            bottom: -50px;
            right: 48px;
            width: 138px;
            height: 174px;
            padding-top: 15px;
            border: 1px solid #e5e5e5;
            background: #fff;
            img {
                display: block;
                width: 110px;
                height: 110px;
                margin: 0 auto;
            }
            p {
                padding-top: 14px;
                text-align: center;
                font-size: 14px;
                color: #333;
            }
        }
    }
    @media(max-width:1450px) {
        right: 10px;
    }
    @media(max-width:1180px) {
        display: none;
    }
}