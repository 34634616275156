.product-page-list {
    padding-bottom: 35px;
    >li {
        float: left;
        width: (100%/3);
    }
    .box {
        display: block;
        padding: 4px;
        margin: 0 10px 25px;
        border-radius: 5px;
        overflow: hidden;
        background: #f3f3f3;
        &:hover {
            background: #405ba8;
            .box-desc {
                color: #fff;
                .icon {
                    color: #fff;
                }
            }
        }
    }
    .box-img {
        position: relative;
        padding-top: (200/270)*100%;
        background: #fff;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .box-desc {
        position: relative;
        display: block;
        padding: 0 30px 0 10px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: #333;
        span {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .icon {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translate(0, -50%);
            line-height: 1;
            font-size: 16px;
            color: #a0a0a0;
        }
    }
    @media(max-width:750px) {
        padding-bottom: 15px;
        >li {
            width: (100%/2);
        }
        .box {
            margin: 0 6px 20px;
        }
        .box-desc {
            padding: 0 24px 0 6px;
            height: 42px;
            line-height: 42px;
            font-size: 14px;
            .icon {
                font-size: 14px;
            }
        }
    }
}

// 产品详情
.product-details-page {
    .details-title {
        padding-bottom: 25px;
        font-size: 24px;
        font-weight: bold;
        color: #333;
        text-align: center;
    }
}

.proInfoSlide {
    .sp-slide {
        .img {
            position: relative;
            display: block;
            padding: 5px;
            width: 100%;
            height: 100%;
            background: #f3f3f3;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    .sp-thumbnails-container {
        background: #f3f3f3;
        padding: 44px 20px;
        box-sizing: content-box;
    }
    .sp-thumbnails {
        .sp-thumbnail-container {
            margin-bottom: 8px;
        }
    }
    .sp-selected-thumbnail {
        .sp-thumbnail {
            border-color: #1275a0;
        }
    }
    .sp-thumbnail-arrow {
        position: absolute;
        left: -50px;
        width: 100px;
        height: 30px;
        background: url('../images/icon05.png') no-repeat center;
    }
    .sp-previous-thumbnail-arrow {
        top: 7px;
    }
    .sp-next-thumbnail-arrow {
        transform: rotate(180deg);
        bottom: 7px;
    }
    .sp-thumbnail {
        position: relative;
        height: 100%;
        border: 2px solid #cacaca;
        border-radius: 4px;
        overflow: hidden;
        img {
            display: block;
            margin: 0 auto;
            max-height: 100%;
        }
    }
}

@media(max-width:830px) {
    .product-details-page {
        .details-title {
            padding-bottom: 15px;
            font-size: 22px;
            font-weight: normal;
        }
    }
    .proInfoSlide {
        .sp-thumbnails-container {
            margin-top: 10px;
            padding: 12px 30px;
        }
        .sp-thumbnails {
            .sp-thumbnail-container {
                margin-bottom: 0;
            }
        }
        .sp-thumbnail-arrow {
            width: 70px;
            height: 24px;
            background-size: 20px;
        }
        .sp-previous-thumbnail-arrow {
            transform: rotate(-90deg);
            top: 0;
            left: -20px;
        }
        .sp-next-thumbnail-arrow {
            transform: rotate(90deg);
            left: auto;
            right: -20px;
            bottom: auto;
        }
    }
}

.product-intro-title {
    display: block;
    margin: 40px 0 15px;
    border-left: 4px solid #405ba8;
    padding-left: 8px;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
    color: #405ba8;
}

.product-intro-box {
    padding: 25px;
    background: #f3f3f3;
    margin-bottom: 10px;
    .left {
        float: left;
        max-width: 370px;
        font-size: 14px;
        line-height: 28px;
        color: #666;
        h4 {
            font-size: 18px;
            font-weight: bold;
            color: #333;
            padding-bottom: 15px;
        }
    }
    .right {
        float: right;
        width: 370px;
    }
    .box-img {
        position: relative;
        padding-top: (314/370)*100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@media(max-width:830px) {
    .product-intro-title {
        margin: 20px 0 15px;
    }
    .product-intro-box {
        padding: px2rem(25px);
        background: #f3f3f3;
        margin-bottom: 15px;
        .left {
            float: none;
            max-width: none;
            margin-bottom: 10px;
        }
        .right {
            float: none;
            width: 100%;
        }
    }
}