.contact-page {
    .map-con {
        display: block;
        border: 6px solid #eee;
        background: #eee;
    }
    .map {
        display: block;
        height: 412px;
    }
    .map-info {
        padding-top: 25px;
        h4 {
            font-size: 24px;
            color: #405ba8;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            color: #333;
        }
    }
    @media(max-width:1150px) {
        .map {
            height: px2rem(500px);
        }
        .map-info {
            padding-top: 20px;
            h4 {
                font-size: 22px;
            }
        }
    }
}

// 留言反馈
.contact-msg-page {
    padding-left: 10px;
    padding-right: 10px;
    .input-group {
        float: left;
        width: 48%;
        margin-bottom: 20px;
        border-radius: 5px;
        background: #edf0f2;
        overflow: hidden;
        .input-word {
            float: left;
            width: 60px;
            height: 50px;
            line-height: 50px;
            padding-left: 12px;
            font-size: 14px;
            color: #333;
        }
        .bd {
            overflow: hidden;
        }
        .input {
            display: block;
            width: 100%;
            height: 50px;
            padding: 0 10px;
            background: transparent;
            border: 0;
            font-size: 14px;
            line-height: 1.6;
            color: #333;
        }
    }
    .base-info {
        .input-group:nth-child(2n+1) {
            margin-right: 2%;
        }
        .input-group:nth-child(2n+2) {
            margin-left: 2%;
        }
    }
    .text-input-con {
        margin-bottom: 35px;
        border-radius: 5px;
        padding: 10px 15px;
        background: #edf0f2;
        .tip-word {
            display: block;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            color: #333;
        }
        .text-input {
            display: block;
            width: 100%;
            height: 180px;
            font-size: 14px;
            line-height: 28px;
            color: #333;
            border: 0;
            resize: none;
            background: transparent;
        }
    }
    .form-btn {
        cursor: pointer;
        display: block;
        width: 100%;
        height: 48px;
        border-radius: 5px;
        border: 0;
        outline: 0;
        background: #405ba8;
        text-align: center;
        font-size: 16px;
        color: #fff;
        &:hover {
            opacity: .95;
        }
    }
    @media(max-width:1150px) {
        padding-left: 0;
        padding-right: 0;
        .input-group {
            width: 100%;
            margin-bottom: 12px;
            border-radius: 2px;
            .input-word {
                height: 44px;
                line-height: 44px;
            }
            .input {
                height: 44px;
            }
        }
        .base-info {
            .input-group:nth-child(2n+1) {
                margin-right: 0;
            }
            .input-group:nth-child(2n+2) {
                margin-left: 0;
            }
        }
        .text-input-con {
            margin-bottom: 12px;
            border-radius: 2px;
        }
        .form-btn {
            height: 44px;
            border-radius: 2px;
        }
    }
}