.new-list-page {
    padding-bottom: 40px;
    .box {
        display: block;
        padding: 15px;
        height: 230px;
        background: #f4f4f4;
        margin-bottom: 20px;
        overflow: hidden;
        &:hover {
            .left img {
                opacity: .9;
            }
            .info {
                .h4 {
                    color: $blue;
                }
                .more {
                    transform: translate(0, 0);
                    color: #fff;
                    background: $blue;
                }
            }
        }
    }
    .left {
        position: relative;
        float: left;
        width: 266px;
        margin-right: 15px;
        overflow: hidden;
        &:before {
            content: '';
            display: block;
            padding-top: (200/266)*100%;
        }
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .info {
        overflow: hidden;
        padding-left: 15px;
        .h4 {
            transition: all .3s;
            padding: 10px 0 5px;
            font-size: 18px;
            color: #333;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .time {
            font-family: 'conv';
            font-size: 16px;
            color: #666;
            margin-bottom: 15px;
        }
        .p {
            font-size: 14px;
            line-height: 24px;
            height: 48px;
            overflow: hidden;
            color: #666;
        }
        .more {
            transition: all .3s;
            transform: translate(-20px, 0);
            display: inline-block;
            margin-top: 30px;
            width: 120px;
            height: 30px;
            line-height: 30px;
            border-radius: 4px;
            font-size: 14px;
            text-align: center;
            color: #333;
        }
    }
    @media(max-width:750px) {
        padding-bottom: 10px;
        .box {
            padding: px2rem(20px);
            height: auto;
            margin-bottom: px2rem(30px);
        }
        .left {
            width: 50%;
            margin-right: px2rem(20px);
        }
        .info {
            padding-left: 0;
            .h4 {
                padding: px2rem(20px) 0;
                font-size: 16px;
            }
            .time {
                font-size: 14px;
                margin-bottom: px2rem(20px);
            }
            .more {
                display: none;
            }
        }
    }
}

// 新闻详情
.new-details-hd {
    text-align: center;
    padding-bottom: 25px;
    h3 {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        margin-bottom: 8px;
    }
    .hd-info {
        span {
            margin: 0 5px;
            font-size: 14px;
            color: #999;
        }
    }
}

.new-detail-bd {
    padding-bottom: 35px;
    overflow: hidden;
    font-size: 14px;
    line-height: 28px;
    color: #666;
    p {
        margin-bottom: 15px;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
}

.new-details-ft {
    .left {
        float: left;
    }
    .word-btn {
        display: block;
        font-size: 14px;
        color: #333;
        margin-bottom: 8px;
        a {
            font-size: 14px;
            color: #405ba8;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .back-btn {
        float: right;
        width: 180px;
        height: 42px;
        line-height: 42px;
        border-radius: 4px;
        text-align: center;
        background: #ebebeb;
        font-size: 14px;
        color: #333;
        &:hover {
            color: #fff;
            background: $blue;
        }
    }
}

@media(max-width:1000px) {
    .new-details-hd {
        padding-bottom: 15px;
        .hd-info {
            span {
                font-size: 13px;
            }
        }
    }
    .new-detail-bd {
        padding-bottom: 20px;
    }
    .new-details-ft {
        .left {
            padding-bottom: 10px;
            width: 100%;
        }
        .back-btn {
            width: 100%;
        }
    }
}