.home-banner {
    position: relative;
    .banner-item {
        display: block;
        width: 100%;
        height: 500px;
        background-position: top center;
        background-repeat: no-repeat;
    }
    .mo-banner-item {
        display: none;
        width: 100%;
        padding-bottom: (350/640)*100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .swiper-pagination {
        font-size: 0;
        bottom: 25px;
    }
    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background: #2f4688;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background: #405ba8;
    }
    @media(max-width: 750px) {
        .banner-item {
            display: none;
        }
        .mo-banner-item {
            display: block;
        }
        .swiper-pagination {
            bottom: 10px;
        }
        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
        }
    }
}
.home-sectioc01 {
    padding: 45px 0;
}
.home-research-con {
    float: left;
    width: 410px;
    margin-right: 30px;
    .item {
        position: relative;
        display: block;
        height: 174px;
        overflow: hidden;
        padding: 38px 26px 0;
        background: #405ba8;
        margin-bottom: 5px;
        border-radius: 4px;
        &:hover {
            .mark {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .icon {
        position: relative;
        float: left;
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border: 1px solid #fff;
        border-radius: 100%;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            color: #fff;
        }
    }
    .info {
        overflow: hidden;
    }
    .title {
        padding: 5px 0;
        color: #fff;
        .cn {
            font-size: 22px;
        }
        .en {
            font-family: 'Arial';
            font-size: 13px;
        }
    }
    .line {
        border-top: 1px solid #215694;
        border-bottom: 1px solid #5c73b5;
        margin-bottom: 8px;
    }
    .p {
        overflow: hidden;
        height: 48px;
        font-size: 14px;
        line-height: 24px;
        color: #d2d7e7;
    }
    .mark {
        visibility: hidden;
        opacity: 0;
        transition: all .3s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .bg01 {
            position: absolute;
            top: 0;
            left: 0;
            background: no-repeat center;
            background-size: cover;
            height: 100%;
            width: 100%;
        }
        .bg02 {
            position: absolute;
            top: 0;
            left: 0;
            background: url('../images/bg03.png') no-repeat center;
            background-size: cover;
            height: 100%;
            width: 100%;
        }
        .word-con {
            position: absolute;
            right: 22px;
            bottom: 16px;
            color: #fff;
            text-align: right;
            .cn {
                font-size: 18px;
            }
            .en {
                font-family: 'Arial';
                font-size: 13px;
            }
        }
    }
}
.home-new-con {
    overflow: hidden;
    .img-cell {
        display: block;
        &:hover {
            .left img {
                opacity: .85;
            }
            .h4 {
                color: $blue;
            }
        }
        .left {
            position: relative;
            float: left;
            width: 232px;
            overflow: hidden;
            border-radius: 4px;
            margin-right: 16px;
            &:before {
                content: '';
                display: block;
                padding-top: (146/232)*100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .right {
            overflow: hidden;
        }
        .h4 {
            padding-bottom: 5px;
            font-size: 18px;
            color: #333;
        }
        .time {
            font-size: 14px;
            font-family: 'conv';
            color: #666;
            padding-bottom: 6px;
        }
        .p {
            font-size: 14px;
            line-height: 24px;
            color: #666;
            height: 48px;
            overflow: hidden;
        }
        .more {
            display: inline-block;
            padding-top: 10px;
            color: #405ba8;
            font-size: 14px;
        }
    }
    .cell {
        transition: all .3s;
        position: relative;
        display: block;
        padding: 0 90px 0 15px;
        margin-top: 10px;
        border-bottom: 1px dashed #d8d8d8;
        &:hover {
            transform: translate(-10px, 0);
            .p {
                color: $blue;
            }
        }
        .icon {
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translate(0, -50%);
            width: 4px;
            height: 4px;
            background: #a0a0a0;
            border-radius: 100%;
        }
        .time {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translate(0, -50%);
            font-size: 14px;
            font-family: 'conv';
            color: #666;
        }
        .p {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #666;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
@media(max-width: 1100px) {
    .home-sectioc01 {
        padding: 20px 8px;
    }
    .home-research-con {
        float: none;
        width: 100%;
        margin-right: 0;
        padding-bottom: 20px;
        .item {
            height: 130px;
            padding: 20px 15px 0;
            margin-bottom: 5px;
            border-radius: 4px;
        }
        .icon {
            width: 70px;
            height: 70px;
            margin-top: 12px;
            span {
                font-size: 30px;
            }
        }
        .title {
            .cn {
                font-size: 20px;
            }
        }
    }
    .home-new-con {
        .img-cell {
            .left {
                width: 44%;
                margin-right: px2rem(20px);
            }
            .h4 {
                font-size: 16px;
                line-height: 21px;
                height: 42px;
                overflow: hidden;
                padding-bottom: 0;
                margin-bottom: px2rem(10px);
            }
            .time {
                padding-bottom: px2rem(10px);
            }
            .p {
                display: none;
            }
            .more {
                padding-top: 0;
            }
        }
        .cell {
            padding: 0 10px 0 15px;
            .time {
                display: none;
            }
        }
    }
}
.home-sectioc002 {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 65px;
    .hd-con {
        position: relative;
        .title {
            float: left;
            border-left: 3px solid #2f4688;
            padding-left: 8px;
            font-size: 24px;
            font-weight: bold;
            line-height: 1;
            color: #333;
        }
    }
    .prev-btn {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 30px;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        background: #405ba8;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
    }
    .next-btn {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        background: #405ba8;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
    }
}
.home-product-box {
    font-size: 0;
}
.home-product-item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    &:hover {
        opacity: .9;
    }
    &:nth-child(2n+1) {
        padding-right: 10px;
    }
    &:nth-child(2n) {
        padding-left: 10px;
    }
    .wp {
        position: relative;
        width: 100%;
        padding-bottom: (290/590)*100%;
        overflow: hidden;
    }
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .word {
        position: absolute;
        color: #fff;
        top: 40px;
        left: 40px;
        z-index: 4;
    }
    .cn {
        font-size: 28px;
        margin-bottom: 5px;
    }
    .en {
        font-size: 14px;
        font-family: Arial;
    }
    @media screen and (max-width: 1000px) {
        .word {
            top: 20px;
            left: 20px;
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        &:nth-child(2n+1) {
            padding-right: 0;
        }
        &:nth-child(2n) {
            padding-left: 0;
        }
        .cn {
            font-size: 20px;
        }
        .en {
            font-size: 12px;
        }
    }
}
.home-product-slide {
    padding-top: 18px;
    .item-wp {
        transition: all .3s;
        display: block;
        border-radius: 5px;
        background: #f3f3f3;
        overflow: hidden;
        &:hover {
            transform: translate(0, -8px);
            background: #405ba8;
            .p {
                color: #fff;
            }
            .arrow {
                color: #fff;
            }
        }
    }
    .item-img {
        display: block;
        padding-top: (210/280)*100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .item-info {
        position: relative;
        padding: 0 40px 0 15px;
        height: 60px;
        line-height: 60px;
    }
    .p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        color: #333;
    }
    .arrow {
        position: absolute;
        top: 0;
        right: 10px;
        font-size: 14px;
        color: #a0a0a0;
    }
}
@media(max-width: 1100px) {
    .home-sectioc002 {
        padding: 10px 8px 30px;
        .hd-con {
            .title {
                font-size: 18px;
            }
        }
    }
}
@media(max-width: 600px) {
    .home-product-slide {
        .item-info {
            padding: 0 30px 0 8px;
            height: 45px;
            line-height: 45px;
        }
        .p {
            font-size: 15px;
        }
    }
}